








































































import { Component, Vue } from 'vue-property-decorator'
import Loader from '@bertazzoni/common/src/components/Loader.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import KeyElementsEdit from '@bertazzoni/back/components/KeyElementsEdit.vue'
import TagsService from '@bertazzoni/common/src/services/TagsService'
import FormError from '@bertazzoni/common/src/components/FormError.vue'

@Component({
  components: {
    Loader,
    FormError,
    Icons,
    KeyElementsEdit
  }
})
export default class NewCom extends Vue {
  private newTags: string[] = ['']
  private selectedCategory = ''
  private newCategory = ''
  private categoryList: string[] = ['None']
  private pageLoaded = false
  private submitLoading = false
  private loadingCategoryError = false
  private submitError = { category: false, tag: false }

  async created(): Promise<void> {
    try {
      this.categoryList = this.categoryList.concat(await TagsService.getAllTagCategories())
    } catch (e) {
      this.loadingCategoryError = true
    }

    this.pageLoaded = true
  }
  categoryChanged(): void {
    if (this.selectedCategory === 'None' || this.selectedCategory === '') this.selectedCategory = ''
    else this.newCategory = ''
    this.checkCategoryError()
  }
  getCreateCategory(): void {
    if (this.newCategory !== '') this.selectedCategory = ''
    this.checkCategoryError()
  }
  async checkErrors(): Promise<void> {
    this.checkCategoryError()
    if (!this.submitError.category) {
      this.submitNewTags()
    }
  }
  checkCategoryError(): void {
    if (this.newCategory === '' && this.selectedCategory === '') {
      this.submitError.category = true
    } else {
      this.submitError.category = false
    }
  }
  async createCategory(): Promise<void> {
    await TagsService.createNewCategory({ id: this.newCategory, tagNames: this.newTags })
  }
  async assignToCategory(): Promise<void> {
    await TagsService.assignNewTagsTo({ id: this.selectedCategory, tagNames: this.newTags })
  }
  async submitNewTags(): Promise<void> {
    this.changeLoading()
    try {
      if (this.newCategory !== '') await this.createCategory()
      else await this.assignToCategory()
      this.changeLoading()
      this.$router.push('/tags')
    } catch (e) {
      this.changeLoading()
    }
  }
  changeLoading(): void {
    this.submitLoading = !this.submitLoading
  }
}
